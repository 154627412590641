<template>
  <div class="live">
    <iframe id="myIframe" :src="link" frameborder="0"></iframe>
  </div>
</template>
<script>
export default {
  name: 'Live',
  data(){
    return {
     link: ''
    }
  },
  mounted(){
    console.log(this.$route.params.no);
    if( window.location.host=='culrcx.kskstudy.com'){
      this.getInfo()
    }
    setTimeout(() => {
      this.link = 'https://54b9ea054c.live.polyv.cn/watch/' + this.$route.params.no;
      this.$forceUpdate()
    }, 1000); //
  },
  methods: {
    getInfo(){
      this.$axios.post('/v1/live/record',{live_id:this.$route.params.live_id},{useLog:true}).then(res=>{
        console.log(res)
      })
    }
  }
}
</script>
<style scoped lang="scss">
iframe{
  width: 100%;
  height: 100vh;
}
</style>